body{
  margin: 0px;
  text-align: center;
  font-size: 24px;
}

input{
  height: 20px;
}

.title{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  background-color: #223344;
}

.margin{
  margin: 20px 0px;
}

button{
  padding: 5px;
  width: 60px;
  cursor: pointer;
}

.btn{
  background-color: #223344;
  color: white;
  font-size: 18px;
  margin: 20px;
  padding: 10px;
  width: 80px;
  border-radius: 5px;
  cursor: pointer;
}